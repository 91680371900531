"use client";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useSelectedLayoutSegment } from "next/navigation";
import { useTranslation } from "react-i18next";

type Props = { href: string; label: string; external?: boolean };

export const DesktopNavLink: React.FC<Props> = ({ href, label, external }) => {
  const segment = useSelectedLayoutSegment();

  const { t, i18n } = useTranslation()

  // console.log("----DesktopNavLink----", href, i18n.language, `/${i18n.language}/${segment}`, segment == null, !!segment, href.startsWith(`/${i18n.language}`))
  return (
    <Link
      href={href}
      target={external ? "_blank" : undefined}
      className={cn("text-white/80 hover:text-white duration-200 text-sm tracking-[0.07px]", {
        "text-white font-bold": (!!segment ? href.startsWith(`/${i18n.language}/${segment}`) : href === `/${i18n.language}`),
      })}
    >
      {label}
    </Link>
  );
};

export const MobileNavLink: React.FC<Props> = ({ href, label, external }) => {
  const segment = useSelectedLayoutSegment();

  const { t, i18n } = useTranslation()
  return (
    <Link
      href={href}
      target={external ? "_blank" : undefined}
      className={cn(
        "text-white/90 hover:text-white duration-200 text-lg font-medium tracking-[0.07px] py-3",
        {
          // "text-white": href.startsWith(`/${segment}`),
          "text-white font-bold": href.startsWith(`/${i18n.language}/${segment}`),
        },
      )}
    >
      {label}
    </Link>
  );
};
